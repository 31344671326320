<template>
  <div class="app page-bg">
    <esports-header />
    <main class="container-page flex-row align-items-center">
      <b-container>
        <b-row class="justify-content-center">
          <b-col md="8" lg="6">
            <h1 class="float-left display-2 mr-4">404</h1>
            <h4 class="pt-3 font-weight-bold">{{ $t('general_inputs.txt_404') }}</h4>
            <p class="text-muted">{{ $t('general_inputs.page_not_found') }}</p>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <esports-footer />
  </div>
</template>

<script>
export default {
  title: "page_not_found",
  name: 'PageNotFound'
}
</script>